<template>
  <div class="bg pon" :class="className" :style="{backgroundImage:imgList.length > 0 ? 'url('+imgList[index].url+')' :''}">
    <div class="class--con">
      <class-but i-s-throw-out @forward="forward" @backOfff="backOfff"/>
      <div class="video" v-if="index === imgList.length - 1">
        <img src="https://img.dwhs.cn/cdn/imgs/shufa_xinshi_2024.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";


export default {
  name: "frameDesigner",
  components: {ClassBut},
  data() {
    return {
      className:null,
      imgList:[],
      index: 0,
      videoUrl:null,
    }
  },
  async created() {
    // console.log(this.$route.query.subjectName)
    if ( this.$route.query.subjectName === '书法' ) {
      for (let i = 1; i <= 3; i++) {
        const images = await import('../../../assets/image/reportClassV1/sf' + i + '.png');
        this.imgList.push({url: images.default});
      }
      let reportClass = localStorage.getItem('reportClass')
      if ( reportClass === '1' ) {
        this.index = this.imgList.length - 1
      }
      localStorage.setItem('reportClass', '0')
     await this.init()
    }else {
      this.className = 'bg14'
    }
  },
  methods: {
    async init() {
      let res = await service.get(`/dwart/an_teaching/hbk/v1/getAdvVideo?classId=${this.$route.query.classId}`)
      this.videoUrl = res.videoShufaUrl
    },
    forward() {
      if (  this.$route.query.subjectName === '书法' ) {
        if (this.index <= this.imgList.length) {
          this.index++
        }
        if (this.index > this.imgList.length - 1) {
          this.$router.push({
            name: 'reportClassV1',
            query: this.$route.query,
          })
          localStorage.setItem('reportClass', '2')
        }
      }else {
        this.$router.push({
          name: 'decorate',
          query: this.$route.query,
        })
      }
    },
    backOfff(){
      if ( this.$route.query.subjectName === '书法' ) {
        this.index--
        if (this.index < 0) {
          this.$router.back(-1)
        }
      }else {
        this.$router.back(-1)
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

  .video {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 1349px;
      height: 778px;
      border: 20px solid #ffe16c;
      border-radius: 20px;
    }
  }

</style>
